import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslatePipe } from '../../../../common/pipes/translate/translate.pipe';
import { EclNotificationsService } from '../../../../ecl/services/ecl-notifications-service/ecl-notifications.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-crew-event-item',
  templateUrl: './crew-event-item.component.html',
  styleUrls: ['./crew-event-item.component.scss'],
  providers: [TranslatePipe],
})
export class CrewEventItemComponent implements OnInit {
  @Input()
  public event: any;
  @Input()
  public onlyHeader: boolean;
  public nextInfoText: string;
  public allNotifications: any;

  constructor(
    @Inject('CrewEventsService') private CrewEventsService,
    @Inject('CrewFunctionsService') private CrewFunctionsService,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('$mdDialog') private $mdDialog,
    private notifications: EclNotificationsService,
    private translatePipe: TranslatePipe,
    protected dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.nextInfoText = this.translatePipe.transform('CREW.DASHBOARD.EVENTS.LAST_INFO');
    this.notifications.getAll().then(notifs => {
      this.allNotifications = notifs;
    });
  }

  public isHotEvent(event): any {
    // nb en attente + nb pas contactés <= nb besoins
    return (
      !this.isClosedEvent(event) &&
      this.CrewEventsService.getNbPotentiallyRemainingUser(event, 'WAITING') <=
        event.nbPersons - this.CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED')
    );
  }

  public isClosedEvent(event): any {
    return this.CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED') >= event.nbPersons;
  }

  public getNbAccepted(event): any {
    return this.CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED');
  }

  public getNbWaiting(event): any {
    return this.CrewEventsService.getNbUserBySituationStatus(event, 'WAITING');
  }

  public getNbRefused(event): any {
    return this.CrewEventsService.getNbUserBySituationStatus(event, 'REFUSED');
  }

  public openEventModal($event, event, notifs): void {
    console.log('before open modal : ', event);
    /*
    this.dialog.open(CrewEventModalComponent, {
      data: {
        functions: this.CrewFunctionsService.getAll,
        isReadOnly: this.$scope.isReadOnly,
        event: event,
        notifs: notifs,
      },
    }).afterClosed().toPromise().then();

    */

    this.$mdDialog
      .show({
        controller: 'CrewEventModalCtrl',
        templateUrl: 'views/crew/modals/event.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          functions: this.CrewFunctionsService.getAll,
          isReadOnly: this.$scope.isReadOnly,
          event,
          notifs: notifs,
        },
      })
      .catch(function (error) {
        console.error("Erreur lors de l'ouverture de la modale : ", error);
      })
      .then((retVal: any) => {
        if (retVal) {
          this.$scope.$root.$broadcast('crew-situation-updated', retVal);
        }
      });
  }

  public openProperEventModal($event, event): void {
    if (this.$rootScope.isPhone) {
      this.openEventModalPhone($event, event);
    } else {
      this.openEventModal($event, event, this.allNotifications);
    }
  }

  public openEventModalPhone($event, event): void {
    this.$mdDialog
      .show({
        controller: 'CrewEventModalCtrl',
        templateUrl: 'views/crew/modals/event.responsive.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          functions: this.CrewFunctionsService.getAll,
          isReadOnly: this.$scope.isReadOnly,
          event,
        },
      })
      .catch(function (error) {
        console.error("Erreur lors de l'ouverture de la modale : ", error);
      })
      .then(retVal => {
        if (retVal) {
          this.$scope.$root.$broadcast('crew-situation-updated', retVal);
        }
      });
  }
}
