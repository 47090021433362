<div class="hol-dialog-wrapper hol-modal">
  <ng-container *ngIf="!isLoading && config && config$ | async; else loading">
    <mat-toolbar>
      <div mat-dialog-title>
        <i *ngIf="isCreate" class="fa fa-plus"></i>
        <span *ngIf="isCreate" class="title">
          {{ moduleConfig.config.translateKey + '.MODALS.' + type + '.NEW_TITLE' | translate }}
        </span>
        <span *ngIf="!isCreate" class="title">
          {{
            moduleConfig.config.translateKey + '.MODALS.' + type + '.EDIT_TITLE'
              | translate
                : {
                    title: item.contentText,
                    date: item.createdAt | time : isUTC
                  }
          }}
        </span>
      </div>

      <app-acl-selector
        *ngIf="(!isCreate && canUpdate) || isCreate"
        [applicability]="applicability"
        [extendACLOnly]="extendACLOnly"
        [formGroup]="form"
        [isCompact]="true"
        [limitTo]="limitTo && limitTo.acl"
        [moduleName]="moduleConfig.config.moduleName"
        [object]="config.item"
        [readOnly]="config.isReadOnly"
        [startAllSelected]="true"
        alwaysOneSelected="true"
        class="acl-full-width"
      >
      </app-acl-selector>
      <button mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-dialog-content>
      <div #scrollTargetModalHol class="content-grid">
        <div class="content-grid-container">
          <app-section-with-title [icon]="'edit'" [titleKey]="'Description'">
            <ng-container *ngTemplateOutlet="titleContent"></ng-container>
          </app-section-with-title>
          <!-- TAG -->
          <ng-container [ngTemplateOutlet]="template_TAG"></ng-container>

          <!-- NOTE -->
          <ng-container *ngTemplateOutlet="noteContent"></ng-container>

          <!-- SECTION -->
          <div *ngFor="let section of sections">
            <app-section-with-title [icon]="section.button.icon" [titleKey]="'COMMON.MODALS.BUTTONS.' + section.button.name | translate">
              <ng-container [ngTemplateOutlet]="section.template"></ng-container>
            </app-section-with-title>
          </div>

          <ng-content></ng-content>
        </div>
        <div class="content-grid-button">
          <ng-container *ngIf="actionMode">
            <button (click)="toggleMode()">
              <span class="material-symbols-outlined iconsize"> arrow_back </span>
              <span>Retour</span>
            </button>
            <ng-container *ngTemplateOutlet="template_NOTIFICATION"></ng-container>
          </ng-container>
          <ng-container *ngIf="!actionMode">
            <ng-container *ngFor="let group of groupButton | keyvalue">
              <p *ngIf="!config.isReadOnly">{{ 'COMMON.MODALS.GROUP_BUTTONS.' + group.key | translate }}</p>
              <ng-container *ngFor="let buttonConfig of group.value">
                <ng-container *ngIf="buttonConfig.type === 'TOGGLE'; then toggleButton; else otherButton"></ng-container>

                <ng-template #toggleButton>
                  <button
                    (click)="!config.isReadOnly && onButtonClick(buttonConfig)"
                    *ngIf="!config.isReadOnly || (config.isReadOnly && isButtonToggled(buttonConfig))"
                    [ngClass]="{ toggled: isButtonToggled(buttonConfig) }"
                    class="{{ buttonConfig.customClass }}"
                  >
                    <span class="material-symbols-outlined iconsize">
                      {{ buttonConfig.icon }}
                    </span>
                    <span>{{ 'COMMON.MODALS.BUTTONS.' + buttonConfig.name | translate }}</span>
                  </button>
                </ng-template>
                <ng-template #otherButton>
                  <button
                    (click)="onButtonClick(buttonConfig)"
                    *ngIf="!config.isReadOnly"
                    [ngClass]="{ activated: isContentDisplay(buttonConfig.name) }"
                    class="{{ buttonConfig.customClass }}"
                  >
                    <span class="material-symbols-outlined iconsize">
                      {{ buttonConfig.icon }}
                    </span>
                    <span
                      >{{ 'COMMON.MODALS.BUTTONS.' + buttonConfig.name | translate }}
                      {{
                        buttonConfig.name == 'NOTIFICATION' && getNotificationActive() > 0 ? '(' + getNotificationActive() + ')' : ''
                      }}</span
                    >
                  </button>
                </ng-template>
              </ng-container>
              <br />
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="action-bar">
        <button (click)="archive()" *ngIf="canArchive" class="action action-refuse">
          <span><i class="icon fa fa-archive"></i></span>
          <span>{{ 'COMMON.BUTTON.ARCHIVE' | translate }}</span>
        </button>
        <button (click)="openHistory()" *ngIf="config.historyItemComponent && !config.isHistory" class="action">
          <span class="material-symbols-outlined">history</span>
          <span>{{ 'COMMON.BUTTON.HISTORY' | translate | uppercase }}</span>
        </button>
        <div class="spacer"></div>
        <button
          (click)="save()"
          *ngIf="(!isReadOnly && ((!isCreate && canUpdate) || isCreate)) || (isReadOnly && canUpdateFromOtherModule); else okButton"
          [disabled]="
            form.invalid || (hasContentText && item.contentText?.trim().length === 0) || !canSave || disabledValidateActions || !isNoteSaved
          "
          class="action action-validate"
        >
          <span><i aria-hidden="true" class="fa fa-check"></i></span>
          <span>{{ (isCreate ? addButtonTrad : updateButtonTrad) | translate }}</span>
        </button>
        <ng-template #okButton>
          <button class="action action-validate" mat-dialog-close>
            <span><i aria-hidden="true" class="fa fa-check"></i></span>
            <span> {{ 'DASHBOARD.MODAL.OK_BUTTON' | translate }}</span>
          </button>
        </ng-template>
      </div>
    </mat-dialog-actions>
  </ng-container>
  <ng-template #loading>
    <div class="hol-modal-loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>

<!-- NEW TEMPLATE -->
<!-- NOTE -->
<ng-template #noteContent>
  <app-section-with-title
    [icon]="'demography'"
    [titleKey]="'Note'"
    *ngIf="!config.isReadOnly || (config.isReadOnly && item.attachments.note)"
  >
    <app-note-markdown
      (isNoteSave)="isNoteSave($event)"
      [attachment]="item.attachments.note"
      [attachments]="item.attachments"
      [context]="context"
      [noteFile]="item.attachments.noteFile"
      [readOnly]="config.isReadOnly"
      [required]="requiredPart.includes('ATTACHMENTS')"
    ></app-note-markdown>
  </app-section-with-title>
</ng-template>

<!-- TAG -->
<ng-template #template_TAG>
  <app-section-with-title
    [icon]="keyButton.get('TAG').icon"
    [titleKey]="'COMMON.MODALS.BUTTONS.' + keyButton.get('TAG').name | translate"
    *ngIf="(!config.isReadOnly && keyButton.has('TAG')) || (config.isReadOnly && config.item['tags'] && config.item['tags'].length)"
  >
    <app-tag-filter
      [(selectedTags)]="config.item['tags']"
      [canAddTag]="true"
      [isCabin]="true"
      [isFilterMode]="false"
      [readOnly]="config.isReadOnly"
      [showOnlyDefaultTags]="config.item['defaultTags'] !== undefined && config.item['defaultTags'].length > 0"
      [showTitle]="false"
      [viewOnly]="config.isReadOnly"
      [required]="requiredPart.includes('TAG')"
    >
    </app-tag-filter>
  </app-section-with-title>
</ng-template>

<ng-template #template_GROUP_LINK>
  <ng-content select=".template_GROUP_LINK"></ng-content>
</ng-template>

<ng-template #template_GROUP_DECISION>
  <ng-content select=".template_GROUP_DECISION"></ng-content>
</ng-template>

<ng-template #template_FLIGHT_LINK>
  <ng-content select=".template_FLIGHT_LINK"></ng-content>
</ng-template>

<ng-template #template_LOGBOOK_LINK>
  <ng-content select=".template_LOGBOOK_LINK"></ng-content>
</ng-template>

<ng-template #template_EVENT_LINK>
  <ng-content select=".template_EVENT_LINK"></ng-content>
</ng-template>

<ng-template #template_APPLICABILITY>
  <ng-content select=".template_APPLICABILITY"></ng-content>
</ng-template>

<ng-template #template_MAKE_TODO>
  <ng-content select=".template_MAKE_TODO"></ng-content>
</ng-template>

<!-- NOTIFICATION -->
<ng-template #template_NOTIFICATION>
  <app-notification-table
    (saveNotifications)="saveNotifications($event)"
    (saveNotifyFunction)="saveNotifyFunction($event)"
    [acl]="item.acl"
    [notifications]="config.notifications"
    [notifyFunction]="config.notifyFunction"
    [type]="type.toLowerCase()"
    [unselectAll]="false"
  ></app-notification-table>
</ng-template>

<ng-template #template_ATTACHMENTS>
  <app-file-and-image-upload
    (onAttachmentUpdated)="updatedAttachment()"
    [attachments]="config.item.attachments"
    [context]="context"
    [disabled]="isReadOnly || (!isCreate && !canUpdate)"
    [hideNote]="true"
    [multipleFileEnabled]="true"
    [required]="requiredPart.includes('ATTACHMENTS')"
  >
  </app-file-and-image-upload>
</ng-template>

<ng-template #template_TIMER_ALERT>
  <div *ngIf="!config.isReadOnly && keyButton.has('TIMER_ALERT')">
    <app-hol-next-info
      (saveNextInfo)="saveNextInfo($event)"
      [hasDoneBtn]="false"
      [isReadOnly]="isReadOnly || (!isCreate && !canUpdate)"
      [isUTC]="isUTC"
      [nextInfo]="config.nextInfo"
    >
    </app-hol-next-info>
  </div>
</ng-template>

<ng-template #NOT_IMPLEMENTED>
  <div>Work in progress</div>
</ng-template>

<ng-template #titleContent>
  <mat-form-field class="hol-modal-title-field">
    <mat-label>{{ moduleConfig.config.translateKey + '.MODALS.' + type + '.TITLE_PLACEHOLDER' | translate }}</mat-label>
    <textarea
      #autosize="cdkTextareaAutosize"
      #message
      [(ngModel)]="config.item.contentText"
      [disabled]="isReadOnly || (canEditTitle ? !canUpdate : !isCreate)"
      [ngModelOptions]="{ standalone: true }"
      cdkAutosizeMaxRows="10"
      cdkAutosizeMinRows="2"
      cdkTextareaAutosize
      class="hol-modal-title-area"
      matInput
      maxlength="{{ contentTextLength }}"
      rows="2"
      tabindex="1"
    ></textarea>
    <mat-hint align="end">{{ message.value.length }} / {{ contentTextLength }}</mat-hint>
  </mat-form-field>
</ng-template>

<!-- END -->
<!--
<ng-template #modalActions>
  <mat-dialog-actions>
    <div class="bottom-modal">

      <ng-container *ngTemplateOutlet="aclSelector"></ng-container>

      <ng-container *ngTemplateOutlet="tagSelector"></ng-container>
    </div>
    <div class="hol-modal__actions">
      <div>
        <ng-content select=".actions-right-addon"></ng-content>
        <button (click)="archive()" *ngIf="!isReadOnly && !isCreate && canArchive" class="hol-archive-btn-secondary"
                mat-raised-button>
          {{ 'DASHBOARD.MODAL.ARCHIVE_BUTTON' | translate }}
        </button>
      </div>
      <div>
        <ng-content select=".actions-addon"></ng-content>
        <button
          (click)="save()"
          *ngIf="(!isReadOnly && ((!isCreate && canUpdate) || isCreate)) || (isReadOnly && canUpdateFromOtherModule); else okButton"
          [disabled]="
            form.invalid || (hasContentText && item.contentText?.trim().length === 0) || !canSave || disabledValidateActions
              ? 'true'
              : 'false'
          "
          class="hol-validate-btn-secondary"
          mat-raised-button
        >
          {{ (isCreate ? addButtonTrad : updateButtonTrad) | translate }}
        </button>
        <ng-template #okButton>
          <button class="hol-validate-btn-secondary" mat-dialog-close mat-raised-button>
            {{ 'DASHBOARD.MODAL.OK_BUTTON' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </mat-dialog-actions>
</ng-template>

<ng-template #tagSelector>
  <div class="hol-dialog-section">
    <app-tag-selector
      [defaultTags]="item['defaultTags']"
      [readOnly]="isReadOnly"
      [selectedTags]="item['tags']"
      [showOnlyDefaultTags]="item['defaultTags'] !== undefined && item['defaultTags'].length > 0"
      class="tag-selector"
    ></app-tag-selector>
  </div>
</ng-template>
<ng-template #aclSelector>
  <app-acl-selector
    *ngIf="!isReadOnly && ((!isCreate && canUpdate) || isCreate)"
    [applicability]="applicability"
    [extendACLOnly]="extendACLOnly"
    [formGroup]="form"
    [limitTo]="limitTo && limitTo.acl"
    [moduleName]="moduleConfig.config.moduleName"
    [object]="item"
    alwaysOneSelected="true"
    class="acl-full-width"
  >
  </app-acl-selector>
</ng-template>
<ng-template #titleContent>
  <mat-form-field class="hol-modal-title-field">
    <mat-label>{{ moduleConfig.config.translateKey + '.MODALS.' + type + '.TITLE_PLACEHOLDER' | translate }}</mat-label>

    <textarea
      #autosize="cdkTextareaAutosize"
      #message
      [(ngModel)]="config.item.contentText"
      [disabled]="isReadOnly || (canEditTitle ? !canUpdate : !isCreate)"
      [ngModelOptions]="{ standalone: true }"
      cdkAutosizeMaxRows="10"
      cdkAutosizeMinRows="2"
      cdkTextareaAutosize
      class="hol-modal-title-area"
      matInput
      maxlength="{{ contentTextLength }}"
      rows="2"
      tabindex="1"
    ></textarea>
    <mat-hint align="end">{{ message.value.length }} / {{ contentTextLength }}</mat-hint>
  </mat-form-field>
</ng-template>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<ng-template #attachments>
  <ng-container *ngIf="hasAttachments">
    <div *ngIf="!isReadOnly && hasAttachmentsMandatory" class="hol-dialog-section">
      <label>{{ moduleConfig.config.translateKey + '.MODALS.' + type + '.ATTACHMENTS_LABEL' | translate }}</label>
    </div>
  </ng-container>
  <div *ngIf="hasAttachments" class="hol-dialog-section">
    <app-file-and-image-upload
      (onAttachmentUpdated)="updatedAttachment()"
      [attachments]="item.attachments"
      [context]="context"
      [disabled]="isReadOnly || (!isCreate && !canUpdate)"
      [hideFile]="true"
      [hideImage]="true"
      [hideLink]="true"
      [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
    >
    </app-file-and-image-upload>
  </div>
</ng-template>
<ng-template #contentRightTop>
  <ng-content select=".content-right-top-addon"></ng-content>
</ng-template>

-->
